(function ($, window, document) {
	var $win 			= $(window),
		$doc			= $(document),
		$aside			= $('.aside'),
		$header			= $('.header'),
		$main			= $('.main'),
		headerHeight	= $header.outerHeight(),
		$introLogo		= $('.js-header-logo-show');

	function window_width() {
		var myWidth = 0;
		if (typeof window.innerWidth == "number") myWidth = window.innerWidth;
		else if (document.documentElement && document.documentElement.clientWidth)
			myWidth = document.documentElement.clientWidth;
		else if (document.body && document.body.clientWidth)
			myWidth = document.body.clientWidth;
		return myWidth;
	}

	function window_height() {
		var myHeight = 0;
		if (typeof window.innerHeight == "number") myHeight = window.innerHeight;
		else if (document.documentElement && document.documentElement.clientHeight)
			myHeight = document.documentElement.clientHeight;
		else if (document.body && document.body.clientHeight)
			myHeight = document.body.clientHeight;
		return myHeight;
	}

	function headerLogoFade() {
		if ( $introLogo.length ) {
			var logoPassed = $introLogo.offset().top < $win.scrollTop();
			
			$header.toggleClass('header--logoshow', logoPassed);
		}
	}

	function scrollTo() {
		$('body').on('click', '.nav a.nav__scroll, a.btn', function(e) {
			if ($win[0].location.pathname !== '/') {
				return;
			}

			var href 	= $(this).attr('href').replace("/", ""),
				$target = $('#section-' + href.replace("#", ""));

			if (!$target.length) {
				return false;
			}

			if (window_width() < 768) {
				$header.removeClass('header--expanded');
			}

			headerHeight = $header.outerHeight();

			$('html, body').animate({
				scrollTop: $target.offset().top - headerHeight - 4
			}, 1400);

			return false;
		});
	}

	function scrollOnLoad() {
		if ($win[0].location.hash !== '') {
			var hash 	= $win[0].location.hash,
				$target = $('.nav a.nav__scroll[href="/' + hash + '"]');

			if ($target.length) {
				$target.trigger('click');
			}
		}
	}

	function clickHandlers() {
		$('.nav-trigger').on('click', function(e) {
			e.preventDefault();

			$header.toggleClass('header--expanded');
		});


		$('.js-play-video').on('click', function (e){
			e.preventDefault();

			var $this 			= $(this),
				$videoholder	= $this.parents('.js-video-section'),
				$video			= $videoholder.find('iframe'),
				src				= $video.data('src') + "?autoplay=1";

			$videoholder.addClass('section-video--played');

			$video.attr('src', src);
		});
	}

	function sliders() {
		if ($('.slider').length) {
			$('.slider .slider__slides').slick({
				nav: false,
				arrows: false,
				dots: true
			});
		}
	}

	function forms() {
		// if ($('select').length) {
		// 	$('select').selectric();
		// }

		if ($('.registerForm').length) {
			jQuery.validator.addMethod("phoneNum", function(value, element) {
			  return this.optional( element ) || /^\+?(?!\*|-)(\d|-){4,}\d$/.test( value );
			}, "Please enter a valid phone number.");

			$('.registerForm').validate({
				ignore:[],
				rules: {
					first_name: 'required',
					last_name: 'required',
					email: {
						required: true,
						email: true
					},
					phone: {
						required: true,
						phoneNum: true,
					}
					
				},
				highlight: function (element, errorClass, validClass) {
				 	$(element).closest("li").addClass('gfield_error');
				},
				unhighlight: function (element, errorClass, validClass) {
				 	$(element).parent().next('li').removeClass('gfield_error');
	    		},
				submitHandler: function(form) {
					$('#gform_submit_button_2665').text('Processing');
					$('#gform_submit_button_2665').prop('disabled', true); 
					   $.ajax({
			                url: "/ajax_submit.php",
			                type: "POST",
			                data: $(form).serialize(),
			                success: function(response) {
				                //$('#gform_wrapper_8').css('border-top', 'none');
				                //$('#registerForm').html('<p>Thanks for Registering</p>');
			                    window.location.href='/thank-you';
			                },
			                error: function(response) {
				                $('#gform_submit_button_2665').text('Submit');
								$('#gform_submit_button_2665').prop('disabled', false); 
				                
				                
			                }
			            });
				}
			});
		}
	}

	function vimeo() {
		$win.on('load', function() {
			$('.vimeoVideo:not(.autoPlay)').each(function() {
				$(this).attr('src', $(this).data('src'));
			});
		});
	}

	$doc.on('ready', function() {
		scrollTo();
		clickHandlers();
		sliders();
		forms();
		vimeo();
	});

	$win.on('load scroll',function(){
		headerLogoFade();
	});

	$win.on('load',function(){
		scrollOnLoad();
	});

})(jQuery, window, document);